"use client";
import { PortalProvider } from "@gorhom/portal";
import { ReactElement } from "react";
import SafeAreaProvider from "./SafeAreaProvider";
import ConfiguredGestureHandlerRootView from "./components/ConfiguredGestureHandlerRootView";

/**
 * Provides all the random providers that random packages require
 */
const PackageProvider = ({ children }: { children: ReactElement }) => {
	return (
		<ConfiguredGestureHandlerRootView>
			<PortalProvider>
				<SafeAreaProvider
					style={{
						flex: 1,
					}}
				>
					{children}
				</SafeAreaProvider>
			</PortalProvider>
		</ConfiguredGestureHandlerRootView>
	);
};

export default PackageProvider;
